import axios from 'axios';

const token = localStorage.getItem('silicom_token');

let headers = {
    'Accept': 'application/json', 
    'Content-Type': 'application/json'
};

if (token !== null) {
    headers.Token = token;
}

const instance = axios.create({
    baseURL: 'https://api.komodo.ch/api',
    headers: headers
});


export default instance;