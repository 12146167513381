import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import requestManager from '../utils/requestManager.js';
import validateEmail from '../utils/validateEmail.js';

function Login() {

    const owaFormRef = useRef();

    const [logging, setLogging] = useState(false);

    const [challengeMFA, setChallengeMFA] = useState(false);
    const [user, setUser] = useState({});
    const [mfaCode, setMfaCode] = useState({
        mfaKey: '',
        code: ''
    });

    const handleChange = (e) => {
        setUser({...user, [e.currentTarget.id]: e.currentTarget.value});
    };

    const handleLogin = (e) => {
        e.preventDefault();

        setLogging(true);
        let url = '/Authentication/GetToken?withMFA=true';
        let data = {
            password: user.password,
            limited: true
        };

        if (validateEmail(user.login)) {
            if (user.login.split('@')[1] === 'sajet.ch' || user.login.split('@')[1] === 'komex.ch') {
                owaFormRef.current.submit();
                return false;
            }
            
            url = '/Authentication/GetTokenUPN?withMFA=true';
            data.account = user.login;
            
        }
        else {
            if (user.login.indexOf('.') !== -1) {
                data.company = user.login.substr(0, user.login.indexOf('.'));
                data.username = user.login.substr((user.login.indexOf('.')+1));
            }
        }
        
        requestManager(url, 'POST', data,
            (res) => {
                console.log(res);
                if (res !== false) {
                    if (res.token) {
                        localStorage.setItem('silicom_token', res.token);
                        window.location.reload();
                    }
                    else if (res.indexOf("MFAKEY:") === 0) {
                        setChallengeMFA(true);
                        setMfaCode({...mfaCode, mfaKey: res});
                    }
                }
                
                setLogging(false);
            },
            {
                400: "L’utilisateur n’est pas valide",
                401: "Utilisateur ou mot de passe incorrect",
                403: "L’utilisateur n’est pas autorisé à utiliser l’API"
            }
        );
    };

    const handleMfaVerify = (e) => {
        e.preventDefault();

        setLogging(true);

        requestManager('Authentication/GetToken_ChallengeMFA', 'POST', mfaCode,
            (res) => {
                if (res.token) {
                    localStorage.setItem('silicom_token', res.token);
                    window.location.reload();
                }
                
                setLogging(false);
            },
            {
                401: "Code de vérification non valide"
            }
        );
    };

    return (<div className="uk-container">        
        <div className="mc-widget" style={{paddingBottom: '0px'}}>           
            <span className="mc-icon flaticon-profile"></span>
            <h5>Se connecter</h5>

            <form onSubmit={!challengeMFA ? handleLogin : handleMfaVerify}>
                <div className="uk-grid" style={{paddingBottom: '35px'}}>
                    {!challengeMFA ? 
                        <>
                            <div className="uk-width-1-2@m">
                                <label className="uk-form-label" htmlFor="login">Utilisateur</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" id="login" type="text" value={user.login || ''} onChange={handleChange} required/>
                                </div>
                            </div>

                            <div className="uk-width-1-2@m">
                                <label className="uk-form-label" htmlFor="password">Mot de passe</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" id="password" type="password" value={user.password || ''} onChange={handleChange} required/>
                                </div>
                            </div>  
                        </> : 
                        <div className="uk-width-1-1@m">
                            <label className="uk-form-label" htmlFor="mfa-code">Code de vérification</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="mfa-code" type="text" value={user.mfaCode} onChange={(e) => setMfaCode({...mfaCode, code: e.currentTarget.value})} required/>
                            </div>
                        </div>
                    }
                    <div className="uk-width-1-1@m"><br/>
                        {!challengeMFA ? 
                            <Link to="/forgot-password" style={{color: '#FA072D'}}>Mot de passe oublié ?</Link> : 
                            <span style={{color: '#FA072D'}} onClick={() => setChallengeMFA(false)}>Précédent</span>
                        }
                    </div>

                    <div className="uk-width-1-1@m">
                        <button className="uk-button uk-margin uk-float-right" type="submit">{!challengeMFA ? "Se connecter" : "Vérifier"} {logging ? <div data-uk-spinner="ratio: 0.5"></div> : null}</button>
                    </div>
                </div>
            </form>

            <form ref={owaFormRef} action="https://he.komodo.ch/owa/auth/owaauth.dll" method="post" style={{display: 'none'}}>
                <input type="hidden" name="username" value={user.login || ''}/>
                <input type="hidden" name="password" value={user.password || ''}/>
                <input type="hidden" name="destination" value="https://he.komodo.ch/owa/"/>
            </form>
        </div>                         
    </div>);
}

export default Login;