import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Nav from'./components/Nav';
import Header from'./components/Header';
import Footer from'./components/Footer';
import Authentication from'./components/Authentication';

import Home from'./pages/Home';
import ForgotPassword from'./pages/ForgotPassword';
import Profile from'./pages/Profile';
import Logout from'./pages/Logout';
import NotFound from'./pages/NotFound';

function App() {

    const [user, setUser] = useState(false);

    return (<>
        <Router>
            <Nav user={user}/>
            <Header user={user}/>

            <div className="mc-content uk-animation-slide-bottom">
                <Routes>
				
                    <Route path="/forgot-password" element={<ForgotPassword />} />

                    <Route  path="/*" element={
                        <Authentication user={user} setUser={setUser}>
						
                            <Routes>
                                <Route path="/*" element={<Home />} /> 
                                <Route path="/profile" element={<Profile user={user} setUser={setUser} />} />
                                <Route exact path="/logout" element={<Logout />} />
                                <Route element={<NotFound />} />
                            </Routes>
						
                        </Authentication>
                    } />
						
                        
                </Routes>

                <Footer/>

            </div>
        </Router>
    </>
    );
}

export default App;
