import { useEffect } from "react";

function Logout() {

    useEffect(() => {
        localStorage.removeItem('silicom_token');
        window.history.pushState('', '', '/');
        window.location.reload();
    }, []);

    return <div className="uk-container">
        <div className="mc-widget uk-text-center">
            <div data-uk-spinner></div>
        </div>
    </div>;
}

export default Logout;