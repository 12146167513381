import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import requestManager from '../utils/requestManager';
import validateEmail from '../utils/validateEmail';
import mailImages from '../utils/mailImages';
import mailBody from '../components/mailBody';

function ForgotPassword() {

    const navigate = useNavigate();

    const [user, setUser] = useState({});
    const [method, setMethod] = useState(null);

    const [codeSending, setCodeSending] = useState(false);
    const [codeSent, setCodeSent] = useState(false);

    const [validationToken, setValidationToken] = useState(null);
    const [validationCode, setValidationCode] = useState(null);

    const [newPassSaving, setNewPassSaving] = useState(false);

    const handleChangeUser = (e) => {
        setUser({...user, [e.currentTarget.id]: e.currentTarget.value});
    };

    const handleReset = (e) => {
        e.preventDefault();
        setCodeSending(true);

        let url = '';
        let data = {};

        if (method === 'email') {
            url = '/Authentication/ResetPasswordInit_Email';
            data = {
                body: mailBody(),
                from: 'support@silicomgroup.ch',
                images: mailImages,
                subject: "Silicom - Demande de réinitialisation du mot de passe"
            };
        }
        else if (method === 'sms') {
            url = '/Authentication/ResetPasswordInit_Mobile';
            data = {
                from: 'SilicomSA',
                text: "Votre code de validation Silicom Group : {CODE}"
            };
        }

        if (validateEmail(user.login)) {
            data.accountUPN = user.login;
        }
        else {
            data.account = user.login;
        }

        
        requestManager(
            url, 
            'POST', 
            data,
            (res) => {
                
                if (res) {
                    setValidationToken(res.actionToken);
                    setCodeSent(true);
                }
                setCodeSending(false);

            },
            {
                200: "Un "+(method === 'email' ? "e-mail" : "SMS")+" contenant le code de vérification vous a été envoyé",
                400: {
                    msg: "Une erreur est survenue lors de l'envoi " + (method === 'email' ? "de l'e-mail" : "du SMS") + " contenant le code de vérification",
                    100 : "L’utilisateur n’existe pas",
                    101 : "L’utilisateur ne peut pas changer son mot de passe",
                    102 : (method === 'email' ? "L’adresse e-mail" : "Le numéro de mobile") + " du compte de l’utilisateur n’est pas valide",
                    103 : "Le SMS n’a pas été envoyé",
                    600 : "Impossible de convertir les données en image (mauvais format)"
                }
            }
        );
    };

    const handleShowPassword  = (e) => {
        e.preventDefault();

        const field = e.target.parentNode.querySelector('input[type="password"], input[type="text"]');
        
        if (!field.disabled) {
            if (field.type == "password") {
                e.target.className = e.target.className.replace(/\bflaticon-lock\b/g, "flaticon-unlocked");
                field.type = "text";
            }
            else {
                e.target.className = e.target.className.replace(/\bflaticon-unlocked\b/g, "flaticon-lock");
                field.type = "password";
            }
        }
        
    };

    const handleConfirm = (e) => {
        e.preventDefault();
        setNewPassSaving(true);

        let data = {
            newPassword: user.new_password,
            userMustChangePwdNextLogon: false,
            validationCode: {
                code: validationCode,
                actionToken: validationToken
            }
        };

        if (validateEmail(user.login)) {
            data.accountUPN = user.login;
        }
        else {
            data.account = user.login;
        }

        requestManager(
            '/Authentication/ResetPasswordFinalize', 
            'POST', 
            data,
            (res) => {
                setNewPassSaving(false);
                
                if (res !== false) {
                    navigate("/");
                }
            },
            {
                200: "Votre mot de passe a été modifié",
                400: {
                    msg: "Une erreur est survenue lors de la modification de votre mot de passe",
                    100 : "L’utilisateur n’existe pas",
                    101 : "Le code de validation n’est pas valable",
                    102 : "L’utilisateur ne peut pas changer son mot de passe",
                    103 : "La politique de sécurité en vigueur n’accepte pas le nouveau mot de passe"
                }
            }
        );
    };

    return (<div className="uk-container">        
        <div className="mc-widget" style={{paddingBottom: "40px"}}>           
            <span className="mc-icon flaticon-profile"></span>
            <h5>Réinitialisation du mot de passe</h5>

            <form onSubmit={handleReset} style={{marginBottom: "40px"}}>
                <div className="uk-grid">
                    <div className="uk-width-1-1@m">
                        <label className="uk-form-label" htmlFor="login">Utilisateur</label>
                        <div className="uk-form-controls">
                            <input className="uk-input" id="login" type="text" value={user.login || ''} onChange={handleChangeUser} disabled={codeSent ? true : false} required/>
                        </div>
                    </div>
                    <div className="uk-width-expand@m">
                        <div className="uk-form-label">Méthode</div>
                        <div className="uk-form-controls uk-form-controls-text">
                            <label><input className="uk-radio" type="radio" name="method" checked={method === 'email' ? true : false} disabled={codeSent ? true : false} onChange={() => setMethod('email')} required/> Par e-mail</label><br />
                            <label><input className="uk-radio" type="radio" name="method" checked={method === 'sms' ? true : false} disabled={codeSent ? true : false} onChange={() => setMethod('sms')} required/> Par SMS</label>
                        </div>
                    </div>
                    <div className="uk-width-auto@m">
                        <button className="uk-button uk-button-default uk-margin uk-float-right" type="submit" style={{marginTop: "34px"}} disabled={codeSent ? true : false}>Réinitialiser {codeSending ? <div data-uk-spinner="ratio: 0.5"></div> : null}</button>
                    </div>
                </div>
            </form>

            <form onSubmit={handleConfirm} style={{marginBottom: "40px"}}>
                <div className="uk-grid">
                    <div className="uk-width-1-3@m">
                        <label className="uk-form-label" htmlFor="validation_code">Code reçu</label>
                        <div className="uk-form-controls">
                            <input className="uk-input" id="validation_code" type="text" value={validationCode || ''} disabled={codeSent ? false : true} onChange={(e) => setValidationCode(e.currentTarget.value)} required/>
                        </div>
                    </div>
                    <div className="uk-width-expand@m">
                        <label className="uk-form-label" htmlFor="new_password">Nouveau mot de passe</label>
                        <div className="uk-form-controls">
                            <div className="uk-inline" style={{width: '100%'}}>
                                <a href="#" className="uk-form-icon uk-form-icon-flip flaticon-lock" style={{outline: "none"}} onClick={handleShowPassword} tabIndex="-1"></a>
                                <input className="uk-input" id="new_password" type="password" value={user.new_password || ''} disabled={codeSent ? false : true} onChange={handleChangeUser} required minLength="8" pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"/>
                            </div>
                            <p className="uk-text-meta" style={{margin: '5px 0'}}>Min. 8 caractères, dans 3 catégories parmi les suivantes : minuscules, majuscules, chiffres et caractères spéciaux</p>
                        </div>
                    </div>
                    <div className="uk-width-auto@m">
                        <button className="uk-button uk-button-default uk-margin uk-float-right" type="submit" style={{marginTop: "34px"}} disabled={codeSent ? false : true}>Valider {newPassSaving ? <div data-uk-spinner="ratio: 0.5"></div> : null}</button>
                    </div>
                </div>
            </form>
        </div>                         
    </div>);
}

export default ForgotPassword;