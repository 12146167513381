import React, { useEffect, useState } from 'react';
import requestManager from '../utils/requestManager';
import Login from '../pages/Login';

const Authentication = (props) => {

    const {user, setUser} = props;

    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('silicom_token');

    useEffect(() => {
        
        if (token !== null) {
            
            requestManager('/ADKomodoUserLimited', 'GET', null,
                (res) => {
                    if (res) {
                        setUser(res);
                        setLoading(false);
                    }
                    else {
                        localStorage.removeItem('silicom_token');
                        setLoading(false);
                    }
                }
            );
        }
        else {
            setLoading(false);
        }
    }, [token]);

    if (loading) {
        return <div className="uk-container">
            <div className="mc-widget uk-text-center">
                <div data-uk-spinner="ratio: 2"></div>
            </div>
        </div>;
    }
    else if (user) {
        return props.children;
    }
    else {
        return <Login />;
    }
};

export default Authentication;