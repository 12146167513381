const mailBody = function() {
    return ('<!DOCTYPE html>\
    <html>\
        <head>\
            <title>Code de validation</title>\
            <meta charset="utf-8" />\
            <meta name="viewport" content="width=device-width, initial-scale=1" />\
            <link href="https://fonts.googleapis.com/css2?family=Hind:wght@400;600&display=swap" rel="stylesheet" />\
            <style>\
                html,body \
                {\
                    font-family: "Hind", sans-serif;\
                    padding:0px;\
                    margin:0px;\
                    font-size:14px;\
                }\
                .container\
                {\
                    max-width:600px;\
                }\
                img \
                {\
                    width:100%;\
                }\
                .content \
                {\
                    padding:15px 30px;\
                }\
                span, a \
                {\
                    color:#e41227;\
                }\
                table \
                {\
                    border-collapse: collapse;\
                    margin:30px 20px;\
                }\
                td {\
                    border: 1px solid #333;\
                    padding:10px\
                }\
                thead \
                {\
                    font-weight:600;\
                }\
                thead,\
                tfoot {\
                    background-color: #e41227;\
                    color: #fff;\
                }\
            </style>\
        </head>\
        <body>\
            <div class="container">\
                <img src="cid:banner"/>\
                <div class="content">\
                    <p>\
                        Bonjour,\
                    </p>\
                    <p>\
                        Une réinitialisation de mot de passe a été demandée sur <a href="https://ec.silicom.group">l\'espace client Silicom Group</a>.\
                    </p>\
                    <p>\
                        Si vous n\'êtes pas à l\'origine de cette action, vous pouvez ignorer cet e-mail.\
                    </p>\
                    <p>\
                        Voici votre code de validation : <b>{CODE}</b>\
                    </p>\
                    <p>\
                        Nous restons à disposition pour toute information complémentaire par téléphone au <span>+41 58 255 38 38</span> ou par email à l’adresse <a href="mailto:support@silicomgroup.ch">support@silicomgroup.ch</a>.\
                    </p>\
                    <p style="text-align:right;margin-right:100px;margin-top:40px;">\
                        Team Silicom Group\
                    </p>\
                </div>\
            </div>\
        </body>\
    </html>');
};

export default mailBody;