import { Routes, Route, Link } from 'react-router-dom';

function Header (props) {

    const { user } = props;

    return (<div className="mc-hero">
        <div className="uk-container">
                    
            <Routes>
                <Route path='/profile' element={
                    <h2 className="uk-animation-fade">
                        Profil
                        <Link to="/" className="mc-retour">&lt; Retour</Link>
                    </h2>
                } />
                <Route path='/forgot-password' element={
                    <h2 className="uk-animation-fade">
                        Mot de passe oublié
                        <Link to="/" className="mc-retour">&lt; Retour</Link>
                    </h2>
                } />
                <Route path='/*' element={!user ? <h2 className="uk-animation-fade">Connexion</h2> : 
                    <h2 className="uk-animation-fade">
                        Bonjour <span id="user-name">{user.displayName}</span> !
                    </h2>
                } />
            </Routes>

            <img src="images/rocket_illustation.svg" className="uk-position-right uk-animation-slide-right" alt="Background logo" />
        </div>
    </div>);
}

export default Header;
