import { useEffect, useState } from "react";
import { notification } from 'uikit';
import requestManager from '../utils/requestManager.js';

function Profile(props) {

    const { user, setUser } = props;

    const [profile, setProfile] = useState({...user});
    const [password, setPassword] = useState({});

    const [saving, setSaving] = useState(false);
    const [savingPassword, setSavingPassword] = useState(false);

    const handleChangeUser = (e) => {
        setProfile({...profile, [e.currentTarget.id]: e.currentTarget.value});
    };

    const handleChangePassword = (e) => {
        setPassword({...password, [e.currentTarget.id]: e.currentTarget.value});
    };

    const handleShowPassword  = (e) => {
        e.preventDefault();

        const field = e.target.parentNode.querySelector('input[type="password"], input[type="text"]');
        
        if (!field.disabled) {
            if (field.type == "password") {
                e.target.className = e.target.className.replace(/\bflaticon-lock\b/g, "flaticon-unlocked");
                field.type = "text";
            }
            else {
                e.target.className = e.target.className.replace(/\bflaticon-unlocked\b/g, "flaticon-lock");
                field.type = "password";
            }
        }
        
    };

    const handleSubmit = (e) => {

        e.preventDefault();
        
        setSaving(true);

        const data = {
            displayName: profile.displayName,
            firstname: profile.firstname,
            lastname: profile.lastname,
            email: profile.email,
            mobile: profile.mobile
        };

        requestManager('/ADKomodoUserLimited', 'PUT', data,
            (res) => {
                if (res) {
                    setUser({...profile});
                }
                else {
                    setProfile({...user});
                }

                setSaving(false);
            },
            {
                200: "Vos informations ont bien été modifiées",
                400: {
                    msg: "Une erreur est survenue lors de l'enregistrement",
                    100: "La ressource n’existe pas",
                    102: "Le nom affiché est déjà utilisé par une autre ressource",
                    200: "Une demande existe déjà pour la ressource",
                    210: "La limite de demandes pour l’utilisateur et le type de ressource est atteinte. Réessayer ultérieurement"
                }
            }
        );
    };

    const handleSubmitPassword = (e) => {

        e.preventDefault();

        if (password.new_password !== password.new_password_confirm) {
            notification({
                message: '<p style="font-size: 0.8em; margin: 0;">La confirmation du nouveau mot de passe n\'est pas valide</p>',
                status: 'warning',
                pos: 'top-center',
                timeout: 6000
            });

            return false;
        }

        setSavingPassword(true);

        requestManager(
            '/Authentication/ChangePassword', 
            'POST', 
            {
                account: user.account,
                accountUPN: user.userPrincipalName,
                oldPassword: password.old_password,
                newPassword: password.new_password,
                userMustChangePwdNextLogon: false
            },
            (res) => {

                if (res) {
                    requestManager(
                        '/Authentication/GetTokenUPN', 
                        'POST', 
                        {
                            account: user.userPrincipalName,
                            password: password.new_password,
                            limited: true
                        },
                        (res) => {
                            localStorage.setItem('silicom_token', res.token);
                            setPassword({});
                        },
                        {
                            401: "Utilisateur ou mot de passe incorrect",
                            403: "L’utilisateur n’est pas autorisé à utiliser l’API"
                        }
                    );
                }

                setSavingPassword(false);
            },
            {
                200: "Votre mot de passe a bien été modifié",
                400: {
                    msg: "Une erreur est survenue lors de la modification du mot de passe",
                    100: "L'utilisateur n'existe pas",
                    101: "L'utilisateur ne peut pas changer son mot de passe",
                    102: "L'ancien mot de passe est incorrect",
                    103: "La politique de sécurité en vigueur n'accepte pas le nouveau mot de passe",
                }
            }
        );
    };

    return (<div className="uk-container">
        <div className="mc-widget" style={{paddingBottom: '35px'}}>           
            <span className="mc-icon flaticon-profile"></span>
            <h4>{user.displayName}</h4>
            <h5>Informations personnelles</h5>

            <form onSubmit={handleSubmit}>
                <div className="uk-grid">

                    <div className="uk-width-1-1@m">
                        <label className="uk-form-label" htmlFor="firstname">Nom affiché</label>
                        <div className="uk-form-controls">
                            <input className="uk-input" id="displayName" type="text" value={profile.displayName || ''} onChange={handleChangeUser} required/>
                        </div>
                    </div>

                    {user.type !== 'Service' ? <>
                        <div className="uk-width-1-2@m">
                            <label className="uk-form-label" htmlFor="firstname">Prénom</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="firstname" type="text" value={profile.firstname || ''} onChange={handleChangeUser} required/>
                            </div>
                        </div>

                        <div className="uk-width-1-2@m">
                            <label className="uk-form-label " htmlFor="lastname">Nom de famille</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="lastname" type="text" value={profile.lastname || ''} onChange={handleChangeUser} required/>
                            </div>
                        </div>
                    </> : null}

                    <div className="uk-width-1-2@m">
                        <label className="uk-form-label" htmlFor="mobile">Mobile</label>
                        <div className="uk-form-controls">
                            <input className="uk-input" id="mobile" type="tel" value={profile.mobile || ''} onChange={handleChangeUser} pattern="[0-9]{11,12}$" />
                        </div>
                        <p className="uk-text-meta" style={{margin: '5px 0'}}>Format international, sans espace (ex. 41791234567)</p>
                    </div>

                    <div className="uk-width-1-2@m">
                        <label className="uk-form-label" htmlFor="email">E-mail</label>
                        <div className="uk-form-controls">
                            <input className="uk-input" id="email" type="mail" value={profile.email || ''} onChange={handleChangeUser} pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,4}$" />
                        </div>
                    </div>

                    <div className="uk-width-1-1@m">
                        <button className="uk-button uk-margin uk-float-right" style={{marginTop: '15px'}} type="submit" disabled={saving}>Valider {saving ? <div data-uk-spinner="ratio: 0.5"></div> : null}</button>
                    </div>

                </div>
                
            </form>
            
            {!user.userCannotChangePwd ? 
                <form onSubmit={handleSubmitPassword}>
                    <div className="uk-grid" style={{marginTop: '0px'}}>
                        <div className="uk-width-1-1">
                            <h5 style={{marginTop: '35px'}}>Changer votre mot de passe</h5>
                        </div>

                        <div className="uk-width-1-2@m">
                            <label className="uk-form-label" htmlFor="old_password">Ancien mot de passe</label>
                            <div className="uk-form-controls">
                                <div className="uk-inline" style={{width: '100%'}}>
                                    <a href="#" className="uk-form-icon uk-form-icon-flip flaticon-lock" style={{outline: "none"}} onClick={handleShowPassword} tabIndex="-1"></a>
                                    <input className="uk-input" id="old_password" type="password" value={password.old_password || ''} onChange={handleChangePassword} required/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-grid" style={{marginTop: '0px'}}>
                        <div className="uk-width-1-2@m">
                            <label className="uk-form-label" htmlFor="new_password">Nouveau Mot de passe</label>
                            <div className="uk-form-controls">
                                <div className="uk-inline" style={{width: '100%'}}>
                                    <a href="#" className="uk-form-icon uk-form-icon-flip flaticon-lock" style={{outline: "none"}} onClick={handleShowPassword} tabIndex="-1"></a>
                                    <input className="uk-input" id="new_password" type="password" value={password.new_password || ''} onChange={handleChangePassword} required minLength="8" pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"/>
                                </div>
                                <p className="uk-text-meta" style={{margin: '5px 0'}}>Min. 8 caractères, dans 3 catégories parmi les suivantes : minuscules, majuscules, chiffres et caractères spéciaux</p>
                            </div>
                        </div>

                        <div className="uk-width-1-2@m">
                            <label className="uk-form-label" htmlFor="new_password_confirm">Retaper le nouveau mot de passe</label>
                            <div className="uk-form-controls">
                                <div className="uk-inline" style={{width: '100%'}}>
                                    <a href="#" className="uk-form-icon uk-form-icon-flip flaticon-lock" style={{outline: "none"}} onClick={handleShowPassword} tabIndex="-1"></a>
                                    <input className="uk-input" id="new_password_confirm" type="password" value={password.new_password_confirm || ''} onChange={handleChangePassword} required minLength="8"/>
                                </div>
                            </div>
                        </div>

                        <div className="uk-width-1-1@m">
                            <button className="uk-button uk-margin uk-float-right" style={{marginTop: '15px'}} type="submit" disabled={savingPassword}>Changer {savingPassword ? <div data-uk-spinner="ratio: 0.5"></div> : null}</button>
                        </div>
                    </div>
                    
                </form> : null}
        </div>
    </div>);
}

export default Profile;