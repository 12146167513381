import { Link } from 'react-router-dom';

function Nav (props) {

    const { user } = props;

    return (<nav className="uk-navbar-container" uk-navbar="align: right">
        <div className="uk-navbar-left"> 
            <ul className="uk-navbar-nav">
                <li>
                    <Link to="/">
                        <img className="mc-logo" src="images/logo_silicom_group.svg"/>
                        <h1>Espace Client</h1>
                    </Link>
                </li>
            </ul>
        </div>
        {user ? <div className="uk-navbar-right">
            <ul className="uk-navbar-nav">
                <li>
                    <a href="#">
                        <span id="user-email">{user.email}</span>
                        <span className="mc-icon flaticon-profile"></span>
                    </a>
                    <div className="uk-navbar-dropdown">
                        <ul className="uk-nav uk-navbar-dropdown-nav">
                            <li><Link to="/profile">Modifier mon profil</Link></li>
                            <li className="uk-nav-divider"></li>
                            <li><Link to="/logout">Se déconnecter</Link></li>
                        </ul>
                    </div>
                    
                </li>
            </ul>
        </div> : null}
    </nav>);
}

export default Nav;
