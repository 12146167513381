import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import requestManager from '../utils/requestManager.js';

function Home() {

    const [hasSDXLoading, setHasSDXLoading] = useState(true);
    const [hasSDX, setHasSDX] = useState(false);
    const [SDXType, setSDXType] = useState('None');
    const [SDXActiveLoading, setSDXActiveLoading] = useState(false);
    const [SDXActive, setSDXActive] = useState(false);
    const [SDXClosing, setSDXClosing] = useState(false);

    useEffect(() => {
        requestManager('/SwissDeskLimited', 'GET', null,
            (res) => {
                //console.log(res.type);
                if (res && res.type !== 'None') {
                    setHasSDX(true);
                    setSDXType(res.type);
                }
                
                setHasSDXLoading(false);
            }
        );
    }, []);

    useEffect(() => {
        if (hasSDX) {
            setSDXActiveLoading(true);
            requestManager('/SwissDeskLimited/GetSessionOpen', 'GET', null,
                (res) => {
                    if (res.state === "Connected") {
                        setSDXActive(true);
                    }
                    
                    setSDXActiveLoading(false);
                }
            );
        }
        
    }, [hasSDX]);

    const handleCloseSwissDesk = () => {
        if (!SDXClosing && window.confirm("Forcer la fermeture de session SwissDesk ?")) {
            setSDXClosing(true);

            requestManager('/SwissDeskLimited/CloseSession', 'POST', null,
                (res) => {
                    if (res) {
                        setSDXActive(false);
                        setSDXActiveLoading(false);
                    }

                    setSDXClosing(false);
                },
                {
                    200: "Session SwissDesk fermée",
                    400: {
                        msg: "Un problème est survenu lors de la fermeture de la session SwissDesk",
                        100 : "L’utilisateur n’a pas accès à SwissDesk"
                    }
                }
            );
        }
    }

    return (<div className="uk-container">
        <ul uk-tab={""}>
            <li><a href="#">Mes actions {hasSDXLoading ? <div data-uk-spinner="ratio: 0.5"></div> : null}</a></li>
            {/*<li><a href="#">Autres</a></li>
            <li><a href="#">Autres 2</a></li>*/}
        </ul>           
        <ul className="uk-switcher uk-margin">
            <li>
                <h3>Actions</h3>
                <div className="uk-grid mc-widgets">
                    {hasSDX && SDXType === 'SwissDesk' ? 
                    <div className="uk-width-1-4@m">  
                        <a href="https://ec.komodo.ch/login.html" target="_blank">
                            <div className="mc-widget">               
                                <span className="mc-icon flaticon-globe"></span>
                                <h4>Bureau distant SD</h4>
                                <h5>Session web</h5>
                            </div>
                        </a>
                    </div> : null}

                    {hasSDX && (SDXType === 'SwissDeskX' || SDXType === 'SmartWorkPlace') ? 
                    <div className="uk-width-1-4@m">  
                        <a href="https://horizonapps.komodo.ch/portal/webclient/index.html#/" target="_blank">
                            <div className="mc-widget">               
                                <span className="mc-icon flaticon-globe"></span>
                                <h4>Bureau distant {SDXType === 'SwissDeskX' ? 'SDX' : 'SWP'}</h4>
                                <h5>Session web</h5>
                            </div>
                        </a>
                    </div> : null}

                    {hasSDX ? 
                    <div className="uk-width-1-4@m">  
                        <div className="mc-widget" onClick={handleCloseSwissDesk}>           
                            <span className="mc-icon flaticon-cloud"></span>
                            <h4>Bureau distant {SDXType === 'SwissDesk' ? 'SD' :  (SDXType === 'SwissDeskX' ? 'SDX' : 'SWP')}</h4>
                            <h5>{!SDXClosing ? 'Forcer la fermeture' : <div data-uk-spinner></div>}</h5>
                            {SDXActiveLoading ? <div data-uk-spinner="ratio: 0.5"></div> : 
                            SDXActive ? 
                                <><span id="sdx-statut" className="mc-statut green">session active</span>
                                <span id="sdx-statut-color" className="mc-color-statut green"></span></> : 
                                <><span id="sdx-statut" className="mc-statut grey">session inactive</span>
                                <span id="sdx-statut-color" className="mc-color-statut grey"></span></>
                            }
                        </div>
                    </div> : null}
                    <div className="uk-width-1-4@m">    
                        <Link to="/profile">
                            <div className="mc-widget">               
                                <span className="mc-icon flaticon-profile"></span>
                                <h4>profil</h4>
                                <h5>Modifier mes infos personnelles</h5>
                            </div>
                        </Link>
                    </div>
                    
                    <div className="uk-width-1-4@m">
                        <Link to="/profile">
                            <div className="mc-widget">                  
                                <span className="mc-icon flaticon-lock"></span>
                                <h4>profil</h4>
                                <h5>Changer mon mot de passe</h5>
                            </div>
                        </Link>
                    </div>
                </div>
            </li>
            {/*<li>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
            <li>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur, sed do eiusmod.</li>*/}
        </ul>
    </div>);
}

export default Home;